import { getAuthHeader } from "@src/app/superagent";
import { ApiResponse } from "@src/appV2/api";
import { environmentConfig } from "@src/appV2/environment";
import { logApiFailureEvent } from "@src/lib/analytics/src";
import { UseMutationOptions, UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import request from "superagent";
import { z } from "zod";

export const saveSignUpReferralConfigRequestSchema = z.object({});
export const saveSignUpReferralConfigResponseSchema = z.object({
  success: z.boolean(),
});

export type SaveSignUpReferralConfigResponse = z.infer<
  typeof saveSignUpReferralConfigResponseSchema
>;
export type SaveSignUpReferralConfigRequest = z.infer<typeof saveSignUpReferralConfigRequestSchema>;

const SAVE_SIGNUP_REFERRAL_CONFIG_ENDPOINT = "/agentprofile/referrals/signup-referrals/save-config";

export const saveSignUpReferralConfig = async (): Promise<
  ApiResponse<SaveSignUpReferralConfigResponse>
> => {
  const { body } = await request
    .post(`${environmentConfig.REACT_APP_BASE_API_URL}${SAVE_SIGNUP_REFERRAL_CONFIG_ENDPOINT}`)
    .set(await getAuthHeader());
  return body;
};

export function useSaveSignUpReferralConfig(
  options: UseMutationOptions<
    ApiResponse<SaveSignUpReferralConfigResponse>,
    AxiosError,
    SaveSignUpReferralConfigRequest
  > = {}
): UseMutationResult<
  ApiResponse<SaveSignUpReferralConfigResponse>,
  AxiosError,
  SaveSignUpReferralConfigRequest
> {
  return useMutation({
    mutationFn: saveSignUpReferralConfig, // TODO: replace with Post from "@src/appV2/api/api" once auth is working in those functions.
    onError: (error: AxiosError) => logApiFailureEvent(error),
    ...options,
  });
}
