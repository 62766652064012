import { formatDuration } from "date-fns";

import { formatDigitWithLeadingZero } from "../../../utils/strings";
import { useCountdownTimer } from "../../hooks/countdownTimer";

const remainingTimeLocaleFormat = {
  xDays: `{{count}} day`,
  xSeconds: "{{count}} second",
  xMinutes: "{{count}} minute",
  xHours: "{{count}} hour",
};
export function CounterDownTimer({ promotionEndingAt }) {
  const countDownDuration = useCountdownTimer(promotionEndingAt);
  const customFormatter = {
    formatDistance: (token, count) => {
      return `${remainingTimeLocaleFormat[token].replace(
        "{{count}}",
        formatDigitWithLeadingZero(count)
      )}${count > 1 ? `s` : ``}`;
    },
  };
  const format = Object.keys(countDownDuration ?? {}).filter(
    (duration) => countDownDuration[duration] > 0
  );
  return (
    <>
      {formatDuration(countDownDuration, {
        zero: true,
        delimiter: ", ",
        format,
        locale: customFormatter,
      })}
    </>
  );
}
