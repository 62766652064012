import { deprecatedDoNotUseLogError } from "@src/appV2/lib/analytics";
import type { Worker } from "@src/appV2/Worker/api/types";
import { useHistory } from "react-router-dom";

import ReferralSubmission from "./ReferralSubmission";
import { ONBOARDING_STAGES } from "../../constants/ONBOARDING_STAGES";
import { updateAgentData } from "../api";
import { OnBoardingComponentProps } from "../model";
import { StyledIonPage } from "../style";

interface ReferralSubmissionPageWrapperProps {
  pageIsVisible: boolean;
  onNext: () => void;
  worker?: Worker;
}

const Page = ({ worker, onNext }: ReferralSubmissionPageWrapperProps) => {
  return <ReferralSubmission worker={worker} onNext={onNext} />;
};

const handleStageUpdate = async (worker?: Worker): Promise<void> => {
  try {
    await updateAgentData({
      stage: ONBOARDING_STAGES.REFERRAL_SUBMISSION,
    });
  } catch (error) {
    deprecatedDoNotUseLogError({
      message: `Unable to update agent data to set onboarding stage to ${
        ONBOARDING_STAGES.REFERRAL_SUBMISSION
      } - ${error?.message}: ${JSON.stringify(error?.stack || error)}`,
      metadata: {
        workerId: worker?.userId,
        msa: worker?.address?.metropolitanStatisticalArea,
      },
    });
  }
};

export default function ReferralSubmissionPageWrapper({
  agent,
  nextStagePath,
}: OnBoardingComponentProps) {
  const history = useHistory<{ skipUserTargetCheck: boolean | undefined }>();

  const onNext = (handleUpdate: boolean) => {
    if (handleUpdate) {
      handleStageUpdate(agent);
    }
    history.replace(`${nextStagePath}`);
  };

  return (
    <StyledIonPage className="onboarding-page">
      <Page pageIsVisible={true} worker={agent} onNext={() => onNext(true)} />
    </StyledIonPage>
  );
}
